import qs from 'qs';

import { defineStore } from 'pinia'
import { useCookies } from "vue3-cookies";
import { authenticated } from '@/main.js';
const { cookies } = useCookies();

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            user: {},
            token: cookies.get("token"),
            invitations: []
        }
    },
    getters: {
        getTutorial(state) {
            return (tutorialName) => {
                if (state.user && Object.keys(state.user).length > 0) {
                    if (state.user.completed_tutorials) {
                        return (state.user.completed_tutorials.indexOf(tutorialName) > -1);
                    }
                }
                return false
            };
        },
        getUnseenNotifications(state) {
            let notifications = []
            notifications = notifications.concat(state.invitations.filter(n => !n?.seen))
            return notifications.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        },
    },
    actions: {
        setToken(token) {
            cookies.set("token", token, 604800, null, null, true, "None");
            this.token = token
            authenticated.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        },
        isAuthenticated() {
            if (this.token && !(Object.keys(this.user).length === 0))
                return true
            return false
        },
        async signUp({ email, firstName, lastName, password, company }) {
            try {
                const body = {
                    "email": email,
                    "first_name": firstName,
                    "last_name": lastName,
                    "company": company,
                    "password": password
                }
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
                const response = await authenticated.post(`/signup`, body, { headers })
                if (response?.status === 200) {
                    await this.login({ "username": email, "password": password })
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async googleLogin(accessToken) {
            try {
                const response = await authenticated.post(`/login/google`, { "access_token": accessToken })
                if (response?.status === 200) {
                    this.setToken(response.data.access_token)
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async login({ username, password, remember }) {
            try {
                const body = { "username": username, "password": password, "remember": remember }
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
                const response = await authenticated.post(`/token`, qs.stringify(body), { headers })
                if (response?.status === 200) {
                    this.setToken(response.data.access_token)
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async fetchUser() {
            try {
                if (this.token) {
                    authenticated.defaults.headers.common['Authorization'] = `Bearer ${this.token}`

                    const response = await authenticated.get('/me')
                    if (response?.status === 200) {
                        this.user = response.data;
                    }
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async editPassword(body) {
            try {
                const response = await authenticated.patch(`/me/password`, body)
                if (response?.status === 200) {
                    this.user = response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async editProfile(body) {
            try {
                const response = await authenticated.patch(`/me`, body)
                if (response?.status === 200) {
                    this.user = response.data
                    return response
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async fetchInvitations() {
            try {
                const response = await authenticated.get(`/me/invitations`)
                if (response?.status === 200) {
                    this.invitations = response.data
                    for (let i = 0; i < this.invitations.length; ++i) {
                        this.invitations[i]['type'] = "invitation"
                    }
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async acceptInvitation(workspaceID) {
            try {
                const response = await authenticated.post(`/me/invitations/${workspaceID}/accept`)
                if (response?.status === 200) {
                    this.invitations = response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async denyInvitation(workspaceID) {
            try {
                const response = await authenticated.post(`/me/invitations/${workspaceID}/deny`)
                if (response?.status === 200) {
                    this.invitations = response.data
                }
            }
            catch (error) {
                throw (error)
            }
        },
        async uploadPicture(file) {
            try {
                const formData = new FormData();
                const headers = {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${this.token}`
                }
                formData.append("upload_file", file);
                const response = await authenticated.put("/me/image", formData, { headers })
                if (response?.status === 200) {
                    this.user = response.data;
                }
            } catch (error) {
                throw (error)
            }
        },
        logout() {
            cookies.remove("token")
            this.$reset()
            this.token = null
        },
    },
});